<template>
  <div class="page-login">
    <el-card class="login-card" :class="{ smaller: isHideDecorate }">
      <div class="row no-gutters text-center">
        <div class="col" v-if="!isHideDecorate">
          <img class="decorate" src="/img/login-decorate.png" />
        </div>
        <div class="col pb-5 pl-3 pr-3">
          <div class="logo">
            <img :src="navbar.logo || '/img/BZOELOGO.png'" alt="">
          </div>
          <div class="app-name row no-gutters align-items-center">
            <div class="col"></div>
            <div class="col-auto">
              {{ navbar.appName }}
            </div>
            <!-- <div class="col-auto pl-2">
              <el-tag type="warning" v-if="isDevEnv">测试服</el-tag>
            </div> -->
            <div class="col"></div>
          </div>
          <div class="app-name-en" style="text-transform: uppercase">{{ navbar.appNameEn }}</div>

          <el-form>
            <el-form-item>
              <el-input placeholder="请输入管理员账户" v-model="form.username" maxlength="11">
                <i slot="prefix" class="el-input__icon el-icon-user"></i>
              </el-input>
            </el-form-item>

            <el-form-item>
              <el-input placeholder="请输入密码" v-model="form.password" type="password" maxlength="40">
                <i slot="prefix" class="el-input__icon el-icon-lock"></i>
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-input placeholder="请输入验证码" v-model="form.captcha" maxlength="4">
                <i slot="prefix" class="el-input__icon el-icon-chat-dot-round"></i>
                <img slot="append" class="captcha" :src="captcha" />
              </el-input>
            </el-form-item>
          </el-form>

          <el-button type="primary" class="w-100 mt-2" @click="submitLogin">登录</el-button>
        </div>
      </div>
    </el-card>

    <div class="copyright">
    </div>
  </div>
</template>

<script lang="js">
import * as API_common from '@/api/common'
import Storage from '@/utils/storage'
import { v1 } from 'uuid'
import { $platform } from '@/libs/platform/platform';
import { $layout } from '@/libs/layout/layout.service';
import { api_model } from '@/libs/common/domain'

export default {
  name: 'login',
  data () {
    return {
      isDevEnv: api_model === 'dev',
      resizeListener: null,
      isHideDecorate: false,
      navbar: $layout.navbar,
      form: {
        username: '',
        password: '',
        captcha: ''
      },
      captcha: '',
      uuid: Storage.getItem('admin_uuid')
    }
  },
  mounted () {
    document.addEventListener("keydown", this.submitLogin);
    const uuid = Storage.getItem('admin_uuid');
    if (uuid) {
      this.uuid = uuid
    } else {
      const _uuid = v1()
      this.uuid = _uuid
      Storage.setItem('admin_uuid', _uuid, { expires: 30 })
    }
    this.resizeListener = $platform.onWindowResize.subscribe(size => {
      this.isHideDecorate = size.width < 1200;
    });
    this.changeCaptcha()
  },
  destroyed () {
    this.resizeListener.unsubscribe();
    document.removeEventListener('keydown', this.submitLogin);
  },
  methods: {
    /** 翻译 */
    translateKey (key) {
      return this.$t('login.' + key)
    },
    /** 更换图片验证码 */
    changeCaptcha () {
      this.captcha = API_common.getValidateCodeUrl('LOGIN', this.uuid)
    },
    /** 表单提交 */
    submitLogin (e) {
      if (!['click', 'keydown'].includes(e.type)) return;
      if (e.type === 'keydown') {
        if (!e.key || e.key !== 'Enter') return;
      }

      const params = this.MixinClone(this.form);
      params.uuid = this.uuid
      this.$store.dispatch('loginAction', params).then(() => {
        const forward = this.$route.query.forward;
        const query = JSON.parse(JSON.stringify(this.$route.query))
        delete query.forward;
        this.$router.push({ path: forward || '/', query })
      }).catch(() => {
        this.changeCaptcha();
      })
    }
  }
}
</script>

<style lang="scss">
@import "./login";
</style>
