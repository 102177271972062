import { BehaviorSubject } from 'rxjs';

export class Platform {
  onWindowResize = new BehaviorSubject(getWindowSize());

  constructor() {
    window.addEventListener('resize', () => {
      this.onWindowResize.next(getWindowSize());
    });
  }
}

export const $platform = new Platform();

function getWindowSize() {
  const { body } = document;
  return {
    width: body.clientWidth,
    height: body.clientHeight,
  };
}
